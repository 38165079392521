export var global = {
    empresa: {
        id: 1,
        nome: 'O Mundo dos Retalhos',    
        logo: 'logo_mr.jpg'
    },
    usuario: { 
        id: 1,
        nome: 'Murilo',
        nivel: 0
    },
    data: '',
    oneSignalId: '',
    // empresas: Array,
    mesaSel: 0,
    comandaSel: 0,
    mesaidSel: 0,
    pedidos: []
};
