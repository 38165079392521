import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('../pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('../pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'cartao',
    loadChildren: () => import('../pages/cartao/cartao.module').then( m => m.CartaoPageModule)
  },
  {
    path: 'operadora',
    loadChildren: () => import('../pages/operadora/operadora.module').then( m => m.OperadoraPageModule)
  },
  {
    path: 'tarifa',
    loadChildren: () => import('../pages/tarifa/tarifa.module').then( m => m.TarifaPageModule)
  },
  {
    path: 'movimento',
    loadChildren: () => import('../pages/movimento/movimento.module').then( m => m.MovimentoPageModule)
  },
  {
    path: 'lancamento',
    loadChildren: () => import('../pages/lancamento/lancamento.module').then( m => m.LancamentoPageModule)
  },
  {
    path: 'sair',
    loadChildren: () => import('../pages/sair/sair.module').then( m => m.SairPageModule)
  },
  {
    path: 'lancamento-detalhe',
    loadChildren: () => import('../pages/lancamento-detalhe/lancamento-detalhe.module').then( m => m.LancamentoDetalhePageModule)
  },
  {
    path: 'inc-lancamento',
    loadChildren: () => import('../pages/inc-lancamento/inc-lancamento.module').then( m => m.IncLancamentoPageModule)
  },
  {
    path: 'dash',
    loadChildren: () => import('../pages/dash/dash.module').then( m => m.DashPageModule)
  },
  {
    path: 'periodo',
    loadChildren: () => import('../pages/periodo/periodo.module').then( m => m.PeriodoPageModule)
  },
  {
    path: 'edita-tarifa',
    loadChildren: () => import('../pages/edita-tarifa/edita-tarifa.module').then( m => m.EditaTarifaPageModule)
  },
  {
    path: 'inc-tarifa',
    loadChildren: () => import('../pages/inc-tarifa/inc-tarifa.module').then( m => m.IncTarifaPageModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('../pages/usuarios/usuarios.module').then( m => m.UsuariosPageModule)
  },
  {
    path: 'inc-usuario',
    loadChildren: () => import('../pages/inc-usuario/inc-usuario.module').then( m => m.IncUsuarioPageModule)
  },
  {
    path: 'edita-usuario',
    loadChildren: () => import('../pages/edita-usuario/edita-usuario.module').then( m => m.EditaUsuarioPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
