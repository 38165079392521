import { Component } from '@angular/core';
import { global } from '../app/global';
import { EventoService } from '../services/evento.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent {

  public selectedIndex = 0;
  public logo: string = global.empresa.logo;
  public usuario: string = global.usuario.nome;

  public appPages = [
    { titulo: 'Lançamentos', url: '/lancamento', icon: 'trash' },
    { titulo: 'Operadoras', url: '/operadora', icon: 'heart' },
    { titulo: 'Cartões', url: '/cartao', icon: 'paper-plane' },
    { titulo: 'Tarifas', url: '/tarifa', icon: 'archive' },
    { titulo: 'Movimentação', url: '/movimento', icon: 'mail' },
    { titulo: 'Dashboard', url: '/dash', icon: 'mail' },
    { titulo: 'Usuários', url: '/usuarios', icon: 'mail' },
    { titulo: 'Sair', url: '/sair', icon: 'warning' },
  ];

  constructor(
    public eventoService: EventoService,
  ) {
    this.eventoService.currentEvent.subscribe(data => {
      // console.log(data);
      if (data.campo === 'usuario') {
        this.usuario = data.valor;
      }
      if (data.campo === 'logo') {
        this.logo = data.valor;
      }
    });
  }
}
